var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('section',{staticClass:"login-container"},[_c('div',{staticClass:"flex flex-col justify-center items-center"},[_c('RouterLink',{attrs:{"to":{
          name: 'Home',
        }}},[_c('img',{style:(("width: " + (_vm.BRAND_CONFIG.LOGO_SIZE.LOGIN_PAGE.WIDTH) + "px;")),attrs:{"src":_vm.BRAND_CONFIG.ORG_DARK_LOGO,"alt":"Logo"}})]),_c('div',{staticClass:"mt-5 login-area"},[_c('div',{staticClass:"px-12 my-5 w-full"},[_c('h1',{staticClass:"mt-16 mb-10 text-lg font-bold text-xl md:text-2xl text-center",staticStyle:{"color":"#505458"}},[_vm._v(" Can't log in? ")]),(_vm.hasNotSentRecoveryLink)?_c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var handleSubmit = ref.handleSubmit;
return [_c('form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.submit)}}},[_c('AppInput',{attrs:{"type":"email","name":"Email","rules":"required","label":"We'll send a recovery link to","placeholder":"e.g. john@example.com"},model:{value:(_vm.form.email),callback:function ($$v) {_vm.$set(_vm.form, "email", $$v)},expression:"form.email"}}),_c('div',{staticClass:"pb-6 mt-6"},[_c('t-button',{staticClass:"w-full relative bg-color text-primary-color",attrs:{"type":"submit"}},[(_vm.isLoading)?_c('loading',{class:"outline-none",attrs:{"active":_vm.isLoading,"height":25,"opacity":0,"color":"#fff","loader":"bars","is-full-page":false}}):_c('span',[_vm._v("Send recovery link")])],1)],1)],1)]}}],null,false,2661809310)}):_c('div',[_c('div',{staticClass:"w-full flex justify-center"},[_c('img',{staticClass:"w-auto h-20 ",attrs:{"src":require("@/assets/marketplace/open-email.svg"),"alt":"open-email"}})]),_c('p',{staticClass:"text-gray-500"},[_vm._v("We sent a recovery link to you at")]),_c('p',{staticClass:"font-bold text-gray-900"},[_vm._v(_vm._s(_vm.form.email))]),_c('hr',{staticClass:"h-2 my-5"}),_c('div',{staticClass:"flex justify-around items-center"},[_c('div',[_c('RouterLink',{staticClass:"text-blue-500 text-sm",attrs:{"to":{
                    name: 'Login',
                  }}},[_c('p',[_vm._v("Return to log in")])])],1),_c('div',{staticClass:"bg-gray-500 w-1 h-1 rounded-full mx-1"}),_c('div',[_c('RouterLink',{staticClass:"text-blue-500 text-sm",attrs:{"to":{
                    name: 'ResetPassword',
                  }}},[_c('p',{on:{"click":function($event){return _vm.resend()}}},[_vm._v(" Resend recovery link ")])])],1)])])],1)])],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }