<template>
  <div>
    <section class="login-container">
      <!-- start:component -->
      <div class="flex flex-col justify-center items-center">
        <RouterLink
          :to="{
            name: 'Home',
          }"
        >
          <img
            :src="BRAND_CONFIG.ORG_DARK_LOGO"
            alt="Logo"
            :style="`width: ${BRAND_CONFIG.LOGO_SIZE.LOGIN_PAGE.WIDTH}px;`"
          />
        </RouterLink>
        <div class="mt-5 login-area">
          <div class="px-12 my-5 w-full">
            <h1
              class="mt-16 mb-5 text-lg font-bold text-xl md:text-2xl text-center"
              style="color: #505458"
            >
              Enter Verification Code
            </h1>
            <p class="text-gray-400 text-center">
              An email with 6 digit verification code sent to
              <span class="text-gray-600 font-semibold">{{ form.email }}</span
              >. Please enter the code in the box below.
            </p>
            <div class="h-6 text-center text-red-500">
              <transition name="fade">
                <div
                  class="text-center text-red-500"
                  v-if="reqStatus === 'failed'"
                >
                  Invalid Credentials
                </div>
              </transition>
            </div>
            <div class="flex items-center justify-center">
              <div class="relative py-5">
                <loading
                  v-if="isLoading"
                  :active="isLoading"
                  :height="25"
                  :opacity="0.5"
                  :color="`#000`"
                  :loader="`bars`"
                  :is-full-page="false"
                  :class="`outline-none`"
                />
                <otp-input
                  @on-complete="onCompleteHandler"
                  :digits="6"
                  mode="separate"
                >
                  <template #errorMessage> There is an error </template>
                </otp-input>
              </div>
            </div>
            <p class="text-center">
              Didn't get the code?
              <span
                class="text-blue-500 font-bold cursor-pointer"
                @click="executeAction('sendEmail')"
                >{{ isResendingEmail ? 'Resending...' : 'Resend' }}</span
              >
            </p>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { useEndpoints } from '@/composables'
import OtpInput from 'otp-input-vue2'
import { xMan } from '@/utils'
import { BRAND_CONFIG } from '@/config/brand.config'
import { POSITION, TYPE } from 'vue-toastification'
export default {
  name: 'VerifyEmail',
  components: { OtpInput },
  data() {
    return {
      BRAND_CONFIG: BRAND_CONFIG,
      form: {
        email: null,
        otp: null,
      },
      isLoading: false,
      isResendingEmail: false,
      reqStatus: null,
      registrationData: null,
    }
  },
  mounted() {
    this.form.email = sessionStorage.getItem('user-email')
    this.registrationData = JSON.parse(
      sessionStorage.getItem('registration-data')
    )
  },
  methods: {
    onCompleteHandler(code) {
      this.form.otp = code
      this.submit()
    },
    async executeAction(type) {
      if (type === 'sendEmail' && this.isResendingEmail === false) {
        this.isResendingEmail = true
        const data = new xMan(this.registrationData).toFormData()

        await this.$http
          .post(useEndpoints.auth.buyerRegister(), data)
          .then(() => {
            this.$toast(`Send Email Successfully`, {
              position: POSITION.BOTTOM_RIGHT,
              type: TYPE.SUCCESS,
            })
          })
          .catch((error) => {
            console.log('err-verify', { error })
            this.$toast(`${error}`, {
              position: POSITION.BOTTOM_RIGHT,
              type: TYPE.ERROR,
            })
          })
          .finally(() => (this.isResendingEmail = false))
      }
    },

    async submit() {
      this.isLoading = true
      const formProxy = this.form

      const data = new xMan(formProxy).toFormData()

      await this.$http
        .post(useEndpoints.auth.buyerEmailVerify(), data)
        .then(() => {
          this.reqStatus = null
          this.$router.push('/login')
        })
        .catch((error) => {
          console.log('err-verify', { error })
          this.reqStatus = 'failed'
        })
        .finally(() => (this.isLoading = false))
    },
  },
}
</script>

<style lang="scss" scoped>
// todo: @use "sass:math";

$breakpoint-sm: 640px;

$login-area-width-mobile: 330px;
$login-area-width-desktop: 440px;

$login-area-height: 470px;

$logo-width: 223px;
$logo-height: 72px;

.login-container {
  position: absolute;
  z-index: 100;
  background: transparent;
  top: 50%;
  left: 50%;
  margin-top: -(($login-area-height + $logo-height) / 2);
  margin-left: -($login-area-width-mobile / 2);
  @media (min-width: $breakpoint-sm) {
    margin-top: -(($login-area-height + $logo-height) / 2);
    margin-left: -($login-area-width-desktop / 2);
  }
}

.login-area {
  width: $login-area-width-mobile;
  border-radius: 0.375rem;
  box-shadow: 0 9px 25px 0 rgba(43, 61, 67, 0.11);
  background-color: #ffffff;
  z-index: 100;
  @media (min-width: 640px) {
    width: $login-area-width-desktop;
  }
}

.login-logo {
  width: $logo-width;
  height: $logo-height;
  object-fit: contain;
  z-index: 100;
}

.login-screen-header {
  background-image: url('~@/assets/otoride/auth/auth-header-v1-1x.png');
  background-repeat: no-repeat;
  width: 100%;
  background-size: cover;
  background-position: bottom;
  height: 60vh;
}

.login-screeen-shape-1 {
  position: absolute;
  border: solid 38px #83ff00;
  right: -58px;
  top: -45px;
  width: 170px;
  height: 170px;
  border-radius: 50%;
}

.login-screen-footer {
  height: 40vh;
  width: 100%;
  // border: 1px solid red;
  background-position: bottom;
  background-repeat: no-repeat;
  margin-bottom: 20rem;
  // object-fit: contain;
  background-size: cover;
  background-image: url('~@/assets/otoride/auth/auth-footer-v2-1x.png');
}
</style>
