<template>
  <div>
    <!-- <section class="overflow-hidden relative" style="height: 100vh">
      <div class="login-screeen-shape-1"></div>
      <div class="login-screen-header"></div>
      <div class="login-screen-footer"></div>
    </section> -->
    <section class="login-container">
      <!-- start:component -->
      <div class="flex flex-col justify-center items-center">
        <RouterLink
          :to="{
            name: 'Home',
          }"
        >
          <img
            :src="BRAND_CONFIG.ORG_DARK_LOGO"
            alt="Logo"
            :style="`width: ${BRAND_CONFIG.LOGO_SIZE.LOGIN_PAGE.WIDTH}px;`"
          />
        </RouterLink>
        <div class="mt-5 login-area">
          <div class="px-12 my-5 w-full">
            <h1
              class="mt-16 mb-10 text-base font-bold md:text-lg text-center"
              style="color: #505458"
            >
              Choose a new password
            </h1>
            <ValidationObserver v-slot="{ handleSubmit }">
              <form @submit.prevent="handleSubmit(submit)">
                <AppInput
                  v-model="form.new_password"
                  type="password"
                  name="Password"
                  rules="required"
                  label=""
                  placeholder=""
                />

                <div class="pb-6 mt-6">
                  <t-button
                    class="w-full relative bg-color text-primary-color"
                    type="submit"
                  >
                    <loading
                      v-if="isLoading"
                      :active="isLoading"
                      :height="25"
                      :opacity="0"
                      :color="`#fff`"
                      :loader="`bars`"
                      :is-full-page="false"
                      :class="`outline-none`"
                    />
                    <span v-else>Save</span>
                  </t-button>
                </div>
              </form>
            </ValidationObserver>
          </div>
        </div>
      </div>

      <!-- end:component -->
    </section>
  </div>
</template>

<script>
import { useEndpoints } from '@/composables'
import { TYPE, POSITION } from 'vue-toastification'
import { xMan } from '@/utils'
import { BRAND_CONFIG } from '@/config/brand.config'
export default {
  name: 'ChangePassword',
  components: {},
  data() {
    return {
      BRAND_CONFIG: BRAND_CONFIG,
      form: {
        new_password: null,
      },
      isLoading: false,
    }
  },
  methods: {
    async submit() {
      this.isLoading = true
      const formProxy = this.form

      const data = new xMan(formProxy).toFormData()
      data.append('token', this.$route.query.signature)
      await this.$http
        .post(useEndpoints.auth.buyerResetPasswordVerify(), data)
        .then((res) => {
          this.$toast(res.data.message, {
            position: POSITION.BOTTOM_RIGHT,
            type: TYPE.SUCCESS,
            timeout: 2000,
          })

          setTimeout(() => {
            this.$router.push('/login')
          }, 2000)
        })
        .catch((error) => {
          console.log('err-verify', { error })
          this.$toast(error.response.data.message, {
            position: POSITION.BOTTOM_RIGHT,
            type: TYPE.ERROR,
          })
        })
        .finally(() => (this.isLoading = false))
    },
  },
}
</script>

<style lang="scss" scoped>
// todo: @use "sass:math";

$breakpoint-sm: 640px;

$login-area-width-mobile: 330px;
$login-area-width-desktop: 440px;

$login-area-height: 470px;

$logo-width: 223px;
$logo-height: 72px;

.login-container {
  position: absolute;
  z-index: 100;
  background: transparent;
  top: 50%;
  left: 50%;
  margin-top: -(($login-area-height + $logo-height) / 2);
  margin-left: -($login-area-width-mobile / 2);
  @media (min-width: $breakpoint-sm) {
    margin-top: -(($login-area-height + $logo-height) / 2);
    margin-left: -($login-area-width-desktop / 2);
  }
}

.login-area {
  width: $login-area-width-mobile;
  border-radius: 0.375rem;
  box-shadow: 0 9px 25px 0 rgba(43, 61, 67, 0.11);
  background-color: #ffffff;
  z-index: 100;
  @media (min-width: 640px) {
    width: $login-area-width-desktop;
  }
}

.login-logo {
  width: $logo-width;
  height: $logo-height;
  object-fit: contain;
  z-index: 100;
}

.login-screen-header {
  background-image: url('~@/assets/otoride/auth/auth-header-v1-1x.png');
  background-repeat: no-repeat;
  width: 100%;
  background-size: cover;
  background-position: bottom;
  height: 60vh;
}

.login-screeen-shape-1 {
  position: absolute;
  border: solid 38px #83ff00;
  right: -58px;
  top: -45px;
  width: 170px;
  height: 170px;
  border-radius: 50%;
}

.login-screen-footer {
  height: 40vh;
  width: 100%;
  // border: 1px solid red;
  background-position: bottom;
  background-repeat: no-repeat;
  margin-bottom: 20rem;
  // object-fit: contain;
  background-size: cover;
  background-image: url('~@/assets/otoride/auth/auth-footer-v2-1x.png');
}
.bg-color {
  background: var(--brand-primary-color);
}

.text-primary-color {
  color: var(--button-text-primary-color);
}
.text-secondary-color {
  color: var(--button-text-secondary-color);
}
</style>
